import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Book, FlipbookService, PageType } from '@labsforge/flipbook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  get demoBook() {
    return this.flipService.book;
  }

  startAt = 0;
  currentPage = 1;

  constructor(
    private elr: ElementRef,
    private flipService: FlipbookService,
  ) {
  }

  ngOnInit() {
    this.flipService.book = {
      width: 1190,
      height: 800,
      zoom: 1,
      cover: {
        front: {
          imageUrl: 'assets/demo/Cover.png',
        },
        back: {
          imageUrl: 'assets/demo/50.png',
        }
      },
      pages: [
        { // start guard section
          imageUrl: 'assets/demo/guard.jpg',
        },
        {
          imageUrl: 'assets/demo/guard.jpg',
          backgroundColor: '#41473A', // don't use if want to see back-cover inverted image
        }, // end guard section
        {
          imageUrl: 'assets/demo/1.png',
        },
        {
          imageUrl: 'assets/demo/2.png',
        },
        { 
          imageUrl: 'assets/demo/3.png',
        },
        {
          imageUrl: 'assets/demo/4.png',
        },
        {
          imageUrl: 'assets/demo/5.png',
        },
        {
          imageUrl: 'assets/demo/6.png',
        },
        {
          imageUrl: 'assets/demo/7.png',
        },
        {
          imageUrl: 'assets/demo/8.png',
        },
        {
          imageUrl: 'assets/demo/9.png',
        },
        {
          imageUrl: 'assets/demo/10.png',
        },
        {
          imageUrl: 'assets/demo/11.png',
        },
        {
          imageUrl: 'assets/demo/12.png',
        },
        {
          imageUrl: 'assets/demo/13.png',
        },
        {
          imageUrl: 'assets/demo/14.png',
        },
        {
          imageUrl: 'assets/demo/15.png',
        },
        {
          imageUrl: 'assets/demo/16.png',
        },
        {
          imageUrl: 'assets/demo/17.png',
        },
        {
          imageUrl: 'assets/demo/18.png',
        },
        { 
          imageUrl: 'assets/demo/19.png',
        },
        {
          imageUrl: 'assets/demo/20.png',
        },
        {
          imageUrl: 'assets/demo/21.png',
        },
        {
          imageUrl: 'assets/demo/22.png',
        },
        {
          imageUrl: 'assets/demo/23.png',
        },
        {
          imageUrl: 'assets/demo/24.png',
        },
        {
          imageUrl: 'assets/demo/25.png',
        },
        {
          imageUrl: 'assets/demo/26.png',
        },
        {
          imageUrl: 'assets/demo/27.png',
        },
        {
          imageUrl: 'assets/demo/28.png',
        },
        {
          imageUrl: 'assets/demo/29.png',
        },
        {
          imageUrl: 'assets/demo/30.png',
        },
        {
          imageUrl: 'assets/demo/31.png',
        },
        {
          imageUrl: 'assets/demo/32.png',
        },
        {
          imageUrl: 'assets/demo/33.png',
        },
        {
          imageUrl: 'assets/demo/34.png',
        },
        { 
          imageUrl: 'assets/demo/35.png',
        },
        {
          imageUrl: 'assets/demo/36.png',
        },
        {
          imageUrl: 'assets/demo/37.png',
        },
        {
          imageUrl: 'assets/demo/38.png',
        },
        {
          imageUrl: 'assets/demo/39.png',
        },
        {
          imageUrl: 'assets/demo/40.png',
        },
        {
          imageUrl: 'assets/demo/41.png',
        },
        {
          imageUrl: 'assets/demo/42.png',
        },
        {
          imageUrl: 'assets/demo/43.png',
        },
        {
          imageUrl: 'assets/demo/44.png',
        },
        {
          imageUrl: 'assets/demo/45.png',
        },
        {
          imageUrl: 'assets/demo/46.png',
        },
        {
          imageUrl: 'assets/demo/47.png',
        },
        {
          imageUrl: 'assets/demo/48.png',
        },
        {
          imageUrl: 'assets/demo/49.png',
        },
        {
          imageUrl: 'assets/demo/guard.jpg',
          backgroundColor: '#41473A', // don't use if want to see back-cover inverted image
        }, // end guard section
      ],
      pageWidth: 585,
      pageHeight: 780,
      startPageType: PageType.Double,
      endPageType: PageType.Double
    } as Book;
  }

  onPrev() {
    this.flipService.prev.next();
  }

  onPlay() {
    this.flipService.play.next();
  }

  onPause() {
    this.flipService.pause.next();
  }

  onNext() {
    this.flipService.next.next();
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (!this.flipService.book) { return; }

    const boundsWidth = this.elr.nativeElement.getBoundingClientRect().width * .8;
    const boundsHeight = this.elr.nativeElement.getBoundingClientRect().height * .6;

    this.flipService.book.zoom = Math.min(boundsWidth / this.flipService.book.width, boundsHeight / this.flipService.book.height);
  }

}
